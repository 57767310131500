import React from 'react'
import { Box, HeadingStyled, MainHeading, TextStyled } from '../Styled/Elements'
import FindValue from './FindValue';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChangeButton from '../../Languages/ChangeButton'

const Percentage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Container fluid >
                <Box className='content-main-container'>
                    <Box className='percentage'>
                        <Box>
                            <MainHeading size='25px'>{t('Conversion_Calculator')}</MainHeading>
                            <TextStyled>{t('Calculator_convert')}</TextStyled>
                        </Box>
                        <MainHeading size='20px' className='heading-font'>{t('Area_Calculator')}</MainHeading>
                        <Box className='mb-3'>
                            <FindValue />
                        </Box>
                    </Box>
                    <Box className='percentage-content'>
                        <MainHeading size='20px' className='heading-font'>{t('Other_Calculator')}</MainHeading>
                        <Box className='box-wrapper'>
                            <Box className='d-flex flex-column gap-1'>
                                <Link to='https://lengthunits.app4orce.com/' target="_blank">{t('Length')}</Link>
                                <Link to='https://temperatureunits.app4orce.com' target="_blank">{t('Temperature')}</Link>
                                <Link className='active' to='https://areaunits.app4orce.com' target="_blank">{t('Area')}</Link>
                                <Link to='https://volumeunits.app4orce.com' target="_blank">{t('Volume')}</Link>
                                <Link to='https://weightunits.app4orce.com' target="_blank">{t('Weight')}</Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className='Faq-content'>
                    <Box>
                        <HeadingStyled>{t('Systems_Units')}</HeadingStyled>
                        <TextStyled>{t('Historically')}
                            <br />
                            {t('systems_measurement')}
                            <br />
                            {t('International_System')}
                        </TextStyled>
                    </Box>
                    <Box>
                        <HeadingStyled>{t('History_Pound')}</HeadingStyled>
                        <TextStyled>
                            {t('eighth')}
                            <br />
                            {t('Over_time')}
                            <br />
                            {t('England')}
                            <br />
                            {t('many_countries')}
                            <br />
                            {t('measurement')}
                        </TextStyled>
                    </Box>
                    <Box>
                        <HeadingStyled>{t('Brief_History')}</HeadingStyled>
                        <TextStyled>
                            {t('decimal')}
                            <br />
                            {t('exchanged')}
                            <br />
                            {t('spread')}
                            <br />
                            {t('International')}
                        </TextStyled>
                    </Box>
                </Box>
            </Container>
            <ChangeButton />
        </>
    )
}

export default Percentage