import React, { useEffect, useState } from 'react'
import { Box, LabelStyled, SpanStyled } from '../Styled/Elements';
import { useTranslation } from 'react-i18next';


const FindValue = () => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const [fromUnit, setFromUnit] = useState('Square_Meter');
    const [toUnit, setToUnit] = useState('Square_Kilometer');
    const [outputValue, setOutputValue] = useState('');
    const [resultValue, setResultValue] = useState(null);
    
    const units = [
        'Square_Meter',
        'Square_Kilometer',
        'Square_Centimeter',
        'Square_Millimeter',
        'Square_Micrometer',
        'Hectare',
        'Square_Mile',
        'Square_Yard',
        'Square_Foot',
        'Square_Inch',
        'Acre',
    ];


/*
    const convertFromMeter = (value, toUnit) => {
        switch (toUnit) {
            case 'Square Meter':
                return value;
            case 'Square_Kilometer':
                return value / 1000000;
            case 'Square_Centimeter':
                return value * 1e4;
            case 'Square_Millimeter':
                return value * 1e6;
            case 'Square_Micrometer':
                return value * 1e12;
            case 'Hectare':
                return value / 1e4;
            case 'Square_Mile':
                return value / 2.58999 * 1e6;
            case 'Square_Yard':
                return value * 1.09361;
            case 'Square_Foot':
                return value * 3.28084;
            case 'Square_Inch':
                return value * 39.3701;

            case 'Acre':
                return value / 9.461e15;
            default:
                return ' ';
        }
    };
*/
    const convertFromMeter = (value, toUnit) => {
        switch (toUnit) {
            case 'Square_Meter':
                return value;
            case 'Square_Kilometer':
                return value / 1000000;
            case 'Square_Centimeter':
                return value * 10000;
            case 'Square_Millimeter':
                return value * 1000000;
            case 'Square_Micrometer':
                return value * 1e12;
            case 'Hectare':
                return value / 10000;
            case 'Square_Mile':
                return value / 2589988; // approximately 2.58999 * 10^6
            case 'Square_Yard':
                return value * 1.19599;
            case 'Square_Foot':
                return value * 10.7639;
            case 'Square_Inch':
                return value * 1550.003;
            case 'Acre':
                return value / 4046.8564224; // International acre
            default:
                return ' ';
        }
    };


    const convertFromKilometer = (value, toUnit) => {
        switch (toUnit) {
            case 'Square_Kilometer':
                return value;
            case 'Square_Meter':
                return value * 1000000;
            case 'Square_Centimeter':
                return value * 1e10;
            case 'Square_Millimeter':
                return value * 1e12;
            case 'Square_Micrometer':
                return value * 1e18;
            case 'Hectare':
                return value * 100;
            case 'Square_Mile':
                return value / 2.58999; // approximately 2.58999 * 10^6
            case 'Square_Yard':
                return value * 1.196e6;
            case 'Square_Foot':
                return value * 1.076e7;
            case 'Square_Inch':
                return value * 1.55e9;
            case 'Acre':
                return value * 247.105; // International acre
            default:
                return ' ';
        }
    };
    const convertFromCentimeter = (value, toUnit) => {
        switch (toUnit) {
            case 'Square_Meter':
                return value / 10000; // 1 square meter = 10000 square centimeters
            case 'Square_Kilometer':
                return value / 1e10; // 1 square kilometer = 1e10 square centimeters
            case 'Square_Centimeter':
                return value;
            case 'Square_Millimeter':
                return value * 100; // 1 square millimeter = 100 square centimeters
            case 'Square_Micrometer':
                return value * 1e8; // 1 square micrometer = 1e8 square centimeters
            case 'Hectare':
                return value / 1e8; // 1 hectare = 1e8 square centimeters
            case 'Square_Mile':
                return value / 2.59e10; // approximately 2.59e10 square centimeters
            case 'Square_Yard':
                return value / 8361.27; // 1 square yard = 8361.27 square centimeters
            case 'Square_Foot':
                return value / 929.03; // 1 square foot = 929.03 square centimeters
            case 'Square_Inch':
                return value / 6.4516; // 1 square inch = 6.4516 square centimeters
            case 'Acre':
                return value / 40468564.2; // International acre
            default:
                return ' ';
        }
    };
    const convertFromMillimeter = (value, toUnit) => {
        switch (toUnit) {
            case 'Square_Meter':
                return value / 1000000; // 1 square meter = 1e6 square millimeters
            case 'Square_Kilometer':
                return value / 1e12; // 1 square kilometer = 1e12 square millimeters
            case 'Square_Centimeter':
                return value / 100; // 1 square centimeter = 100 square millimeters
            case 'Square_Millimeter':
                return value;
            case 'Square_Micrometer':
                return value * 1e6; // 1 square micrometer = 1e6 square millimeters
            case 'Hectare':
                return value / 1e10; // 1 hectare = 1e10 square millimeters
            case 'Square_Mile':
                return value / 2.59e12; // approximately 2.59e12 square millimeters
            case 'Square_Yard':
                return value / 836127.36; // 1 square yard = 836127.36 square millimeters
            case 'Square_Foot':
                return value / 92903.04; // 1 square foot = 92903.04 square millimeters
            case 'Square_Inch':
                return value / 645.16; // 1 square inch = 645.16 square millimeters
            case 'Acre':
                return value / 4046856422.4; // International acre
            default:
                return ' ';
        }
    };
    const convertFromMicrometer = (value, toUnit) => {
        switch (toUnit) {
            case 'Square_Meter':
                return value / 1e12; // 1 square meter = 1e12 square micrometers
            case 'Square_Kilometer':
                return value / 1e18; // 1 square kilometer = 1e18 square micrometers
            case 'Square_Centimeter':
                return value / 1e8; // 1 square centimeter = 1e8 square micrometers
            case 'Square_Millimeter':
                return value / 1e6; // 1 square millimeter = 1e6 square micrometers
            case 'Square_Micrometer':
                return value; // 1 square micrometer = 1 square micrometer
            case 'Hectare':
                return value / 1e16; // 1 hectare = 1e16 square micrometers
            case 'Square_Mile':
                return value / 2.59e18; // approximately 2.59e18 square micrometers
            case 'Square_Yard':
                return value / 8.3613e9; // 1 square yard = 8.3613e9 square micrometers
            case 'Square_Foot':
                return value / 9.2903e7; // 1 square foot = 9.2903e7 square micrometers
            case 'Square_Inch':
                return value / 6.4516e6; // 1 square inch = 6.4516e6 square micrometers
            case 'Acre':
                return value / 4.0469e15; // International acre
            default:
                return ' ';
        }
    };
    const convertFromHectare = (value, toUnit) => {
        switch (toUnit) {
            case 'Square_Meter':
                return value * 10000; // 1 hectare = 10000 square meters
            case 'Square_Kilometer':
                return value / 100; // 1 hectare = 0.01 square kilometers
            case 'Square_Centimeter':
                return value * 100000000; // 1 hectare = 100000000 square centimeters
            case 'Square_Millimeter':
                return value * 10000000000; // 1 hectare = 10000000000 square millimeters
            case 'Square_Micrometer':
                return value * 1e16; // 1 hectare = 1e16 square micrometers
            case 'Hectare':
                return value; // 1 hectare = 1 hectare
            case 'Square_Mile':
                return value / 259; // 1 hectare ≈ 0.00386102 square miles
            case 'Square_Yard':
                return value * 11959.9; // 1 hectare ≈ 11959.9 square yards
            case 'Square_Foot':
                return value * 107639.1; // 1 hectare ≈ 107639.1 square feet
            case 'Square_Inch':
                return value * 15500031; // 1 hectare ≈ 15500031 square inches
            case 'Acre':
                return value * 2.47105; // 1 hectare ≈ 2.47105 acres
            default:
                return ' ';
        }
    };
    const convertFromMile = (value, toUnit) => {
        switch (toUnit) {
            case 'Square_Meter':
                return value * 2.59e6; // 1 square mile ≈ 2.59e6 square meters
            case 'Square_Kilometer':
                return value * 2.58999; // 1 square mile ≈ 2.58999 square kilometers
            case 'Square_Centimeter':
                return value * 2.59e10; // 1 square mile ≈ 2.59e10 square centimeters
            case 'Square_Millimeter':
                return value * 2.59e13; // 1 square mile ≈ 2.59e13 square millimeters
            case 'Square_Micrometer':
                return value * 2.59e19; // 1 square mile ≈ 2.59e19 square micrometers
            case 'Hectare':
                return value * 259; // 1 square mile ≈ 259 hectares
            case 'Square_Mile':
                return value; // 1 square mile = 1 square mile
            case 'Square_Yard':
                return value * 3.098e6; // 1 square mile ≈ 3.098e6 square yards
            case 'Square_Foot':
                return value * 2.788e7; // 1 square mile ≈ 2.788e7 square feet
            case 'Square_Inch':
                return value * 4.014e9; // 1 square mile ≈ 4.014e9 square inches
            case 'Acre':
                return value * 640; // 1 square mile = 640 acres
            default:
                return ' ';
        }
    };
    const convertFromYard = (value, toUnit) => {
        switch (toUnit) {
            case 'Square_Meter':
                return value / 1.196; // 1 square yard ≈ 1.196 square meters
            case 'Square_Kilometer':
                return value / 1.196e6; // 1 square yard ≈ 1.196e6 square kilometers
            case 'Square_Centimeter':
                return value * 8361.27; // 1 square yard ≈ 8361.27 square centimeters
            case 'Square_Millimeter':
                return value * 836127.36; // 1 square yard ≈ 836127.36 square millimeters
            case 'Square_Micrometer':
                return value * 8.3613e9; // 1 square yard ≈ 8.3613e9 square micrometers
            case 'Hectare':
                return value / 1.196e7; // 1 square yard ≈ 1.196e7 hectares
            case 'Square_Mile':
                return value / 3.098e6; // 1 square yard ≈ 3.098e6 square miles
            case 'Square_Yard':
                return value; // 1 square yard = 1 square yard
            case 'Square_Foot':
                return value * 9; // 1 square yard = 9 square feet
            case 'Square_Inch':
                return value * 1296; // 1 square yard = 1296 square inches
            case 'Acre':
                return value / 4840; // 1 square yard ≈ 1/4840 acres
            default:
                return ' ';
        }
    };
    const convertFromFoot = (value, toUnit) => {
        switch (toUnit) {
            case 'Square_Meter':
                return value / 10.764; // 1 square foot ≈ 0.092903 square meters
            case 'Square_Kilometer':
                return value / 1.076e7; // 1 square foot ≈ 1.076e-7 square kilometers
            case 'Square_Centimeter':
                return value * 929.03; // 1 square foot ≈ 929.03 square centimeters
            case 'Square_Millimeter':
                return value * 92903.04; // 1 square foot ≈ 92903.04 square millimeters
            case 'Square_Micrometer':
                return value * 9.2903e7; // 1 square foot ≈ 9.2903e7 square micrometers
            case 'Hectare':
                return value / 1.076e8; // 1 square foot ≈ 1.076e-8 hectares
            case 'Square_Mile':
                return value / 2.788e7; // 1 square foot ≈ 2.788e-7 square miles
            case 'Square_Yard':
                return value / 9; // 1 square foot ≈ 1/9 square yards
            case 'Square_Foot':
                return value; // 1 square foot = 1 square foot
            case 'Square_Inch':
                return value * 144; // 1 square foot = 144 square inches
            case 'Acre':
                return value / 43560; // 1 square foot ≈ 1/43560 acres
            default:
                return ' ';
        }
    };
    const convertFromInch = (value, toUnit) => {
        switch (toUnit) {
            case 'Square_Meter':
                return value / 1550.003; // 1 square inch ≈ 0.00064516 square meters
            case 'Square_Kilometer':
                return value / 1.55e9; // 1 square inch ≈ 6.4516e-10 square kilometers
            case 'Square_Centimeter':
                return value * 6.4516; // 1 square inch ≈ 6.4516 square centimeters
            case 'Square_Millimeter':
                return value * 645.16; // 1 square inch ≈ 645.16 square millimeters
            case 'Square_Micrometer':
                return value * 6.4516e6; // 1 square inch ≈ 6.4516e6 square micrometers
            case 'Hectare':
                return value / 1.55e7; // 1 square inch ≈ 6.4516e-8 hectares
            case 'Square_Mile':
                return value / 4.014e9; // 1 square inch ≈ 2.491e-10 square miles
            case 'Square_Yard':
                return value / 1296; // 1 square inch ≈ 1/1296 square yards
            case 'Square_Foot':
                return value / 144; // 1 square inch ≈ 1/144 square feet
            case 'Square_Inch':
                return value; // 1 square inch = 1 square inch
            case 'Acre':
                return value / 6.273e6; // 1 square inch ≈ 1.594e-7 acres
            default:
                return ' ';
        }
    };

    const convertFromAcre = (value, toUnit) => {
        switch (toUnit) {
            case 'Square_Meter':
                return value * 4046.856; // 1 acre ≈ 4046.856 square meters
            case 'Square_Kilometer':
                return value * 0.004047; // 1 acre ≈ 0.004047 square kilometers
            case 'Square_Centimeter':
                return value * 4.046856e7; // 1 acre ≈ 4.046856e7 square centimeters
            case 'Square_Millimeter':
                return value * 4.046856e9; // 1 acre ≈ 4.046856e9 square millimeters
            case 'Square_Micrometer':
                return value * 4.046856e15; // 1 acre ≈ 4.046856e15 square micrometers
            case 'Hectare':
                return value * 0.404686; // 1 acre ≈ 0.404686 hectares
            case 'Square_Mile':
                return value * 0.0015625; // 1 acre ≈ 0.0015625 square miles
            case 'Square_Yard':
                return value * 4840; // 1 acre ≈ 4840 square yards
            case 'Square_Foot':
                return value * 43560; // 1 acre ≈ 43560 square feet
            case 'Square_Inch':
                return value * 6.273e6; // 1 acre ≈ 6.273e6 square inches
            case 'Acre':
                return value; // 1 acre = 1 acre
            default:
                return ' ';
        }
    };

    const convertUnits = () => {
        const value = parseFloat(inputValue);

        if (isNaN(value)) {
            setOutputValue('');
            return;
        }

        let result;

        switch (fromUnit) {
            case 'Square_Meter':
                result = convertFromMeter(value, toUnit);
                break;
            case 'Square_Kilometer':
                result = convertFromKilometer(value, toUnit);
                break;
            case 'Square_Centimeter':
                result = convertFromCentimeter(value, toUnit);
                break;
            case 'Square_Millimeter':
                result = convertFromMillimeter(value, toUnit);
                break;
            case 'Square_Micrometer':
                result = convertFromMicrometer(value, toUnit);
                break;
            case 'Hectare':
                result = convertFromHectare(value, toUnit);
                break;
            case 'Square_Mile':
                result = convertFromMile(value, toUnit);
                break;
            case 'Square_Yard':
                result = convertFromYard(value, toUnit);
                break;
            case 'Square_Foot':
                result = convertFromFoot(value, toUnit);
                break;
            case 'Square_Inch':
                result = convertFromInch(value, toUnit);
                break;
            case 'Acre':
                result = convertFromAcre(value, toUnit);
                break;
            // Add cases for other units as needed
            default:
                setOutputValue('Invalid unit selected');
                // alert('Invalid unit selected.');
                return;
        }

        setOutputValue(result.toString());
        setResultValue(`${value} ${t(fromUnit)} = ${result.toString()} ${t(toUnit)}`);
    };


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleFromUnitChange = (e) => {
        setFromUnit(e.target.value);
    };

    const handleToUnitChange = (e) => {
        setToUnit(e.target.value);
    };

    useEffect(() => {
        convertUnits();
    }, [inputValue, fromUnit, toUnit]);

    return (
        <Box className='box-wrapper my-4'>
            <Box className='percentage-field'>
                <Box className='unit-converter'>
                    <LabelStyled>{t('From')}</LabelStyled>
                    <input type="text" value={inputValue} onChange={handleInputChange} />
                    <select value={fromUnit} onChange={handleFromUnitChange}>
                        {units.map((unit) => (
                            <option key={unit} value={unit}>
                                {t(unit)}
                            </option>
                        ))}
                    </select>
                </Box>
                <Box className='button-select'>
                    <button className='btn btn-outline-secondary mt-4' >
                        =
                    </button>
                </Box>
                <Box className='unit-converter'>
                    <LabelStyled>{t('To')}</LabelStyled>
                    <input type="text" value={outputValue} readOnly />
                    <select value={toUnit} onChange={handleToUnitChange}>
                        {units.map((unit) => (
                            <option key={unit} value={unit}>
                                {t(unit)}
                            </option>
                        ))}
                    </select>
                </Box>
            </Box>
            {
                resultValue !== null && (
                    <Box className='mt-4 d-flex align-items-center justify-content-center'>
                        <SpanStyled className='btn btn-danger mx-2'>{t('Result')}: </SpanStyled>
                        <SpanStyled className='btn btn-outline-success mx-2'>{resultValue}</SpanStyled>
                    </Box>
                )
            }


        </Box>

    )
}

export default FindValue